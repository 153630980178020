import {Component, Inject, Optional} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {HotelGroup} from "../../pages/masters/classes/hotel-group";

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  title: string = '';
  message: string = '';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message;
  }

  confirm() {
    this.dialogRef.close({event: true});
  }

  cancel() {
    this.dialogRef.close({event: false});
  }
}
