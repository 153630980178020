import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../classes/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: BehaviorSubject<User> = new BehaviorSubject<User>(new User(null));
  user$: Observable<User> = this.user.asObservable();

  constructor() { }

  setUser(u: User): void {
    this.user.next(u);
  }

  getUser(): User {
    return this.user.getValue();
  }

  get getUserFirstName(): string {
    return this.user?.getValue()?.firstName;
  }

  get getFullUserName(): string {
    return this.user?.getValue()?.firstName + ' ' + this.user?.getValue()?.lastName;
  }

  get getUserEmail(): string {
    return this.user?.getValue()?.email;
  }

  get getUserId(): number {
    return this.user?.getValue()?.id;
  }

}
