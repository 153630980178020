import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation, OnInit, ViewChild, ElementRef, OnDestroy,
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {UserService} from "../../../../services/user.service";
import {Hotel} from "../../../../pages/masters/classes/hotel";
import {MasterProvider} from "../../../../providers/master.provider";

interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
  color: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
    FormsModule,
    MatMenuModule,
    MatSidenavModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  providers: [MasterProvider],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: '/assets/images/flag/icon-flag-en.svg',
  };

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];
  headerHotelList: Hotel[] = [];
  filteredHotelOptions: Hotel[] = [];
  hotelSelectionControl = new FormControl('');
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  masterHotelSub: any;

  constructor(
    public coreService: CoreService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public userService: UserService,
    private masterProvider: MasterProvider
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.masterHotelSub = this.coreService.hotelMasterDataChanged.subscribe(value => {
      if (value) {
        this.getHotels();
      }
    });
    this.getHotels();
  }

  ngOnDestroy() {
    if (this.masterHotelSub) {
      this.masterHotelSub.unsubscribe();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  notifications: notifications[] = [
    {
      id: 1,
      img: '/assets/images/profile/user-1.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
    {
      id: 2,
      img: '/assets/images/profile/user-2.jpg',
      title: 'New message received',
      subtitle: 'Salma sent you new message',
    },
    {
      id: 3,
      img: '/assets/images/profile/user-3.jpg',
      title: 'New Payment received',
      subtitle: 'Check your earnings',
    },
    {
      id: 4,
      img: '/assets/images/profile/user-4.jpg',
      title: 'Jolly completed tasks',
      subtitle: 'Assign her new tasks',
    },
    {
      id: 5,
      img: '/assets/images/profile/user-5.jpg',
      title: 'Roman Joined the Team!',
      subtitle: 'Congratulate him',
    },
  ];

  profiledd: profiledd[] = [
    {
      id: 1,
      img: 'wallet',
      color: 'primary',
      title: 'My Profile',
      subtitle: 'Account Settings',
      link: '/',
    },
    {
      id: 2,
      img: 'shield',
      color: 'success',
      title: 'My Inbox',
      subtitle: 'Messages & Email',
      link: '/',
    },
    {
      id: 3,
      img: 'credit-card',
      color: 'error',
      title: 'My Tasks',
      subtitle: 'To-do and Daily Tasks',
      link: '/',
    },
  ];
  apps: apps[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-dd-chat.svg',
      title: 'Chat Application',
      subtitle: 'Messages & Emails',
      link: '/apps/chat',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'Todo App',
      subtitle: 'Completed task',
      link: '/apps/todo',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-dd-invoice.svg',
      title: 'Invoice App',
      subtitle: 'Get latest invoice',
      link: '/apps/invoice',
    },
    {
      id: 4,
      img: '/assets/images/svgs/icon-dd-date.svg',
      title: 'Calendar App',
      subtitle: 'Get Dates',
      link: '/apps/calendar',
    },
    {
      id: 5,
      img: '/assets/images/svgs/icon-dd-mobile.svg',
      title: 'Contact Application',
      subtitle: '2 Unsaved Contacts',
      link: '/apps/contacts',
    },
    {
      id: 6,
      img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
      title: 'Tickets App',
      subtitle: 'Create new ticket',
      link: '/apps/tickets',
    },
    {
      id: 7,
      img: '/assets/images/svgs/icon-dd-message-box.svg',
      title: 'Email App',
      subtitle: 'Get new emails',
      link: '/apps/email/inbox',
    },
    {
      id: 8,
      img: '/assets/images/svgs/icon-dd-application.svg',
      title: 'Courses',
      subtitle: 'Create new course',
      link: '/apps/courses',
    },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/login',
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register',
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error',
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes',
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee',
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo',
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview',
    },
  ];

  getHotels() {
    this.masterProvider.getGetHotelBindByUserId(this.userService.getUserId).subscribe(resp => {
      if (resp.statusCode === 1) {
        this.headerHotelList = Hotel.parseArray(resp.data);
        this.filteredHotelOptions = this.headerHotelList.slice();
        if (this.headerHotelList.length) {
          if (localStorage.getItem('mainHotel')) {
            const storedObj = new Hotel(JSON.parse(localStorage.getItem('mainHotel')));
            const idx = this.headerHotelList.findIndex(p => p.id === storedObj.id);
            if (idx > -1) {
              this.coreService.mainSelectedHotel = this.headerHotelList[idx];
              this.hotelSelectionControl.setValue(this.headerHotelList[idx].name);
            } else {
              this.saveFirstMainHotelData();
            }
          } else {
            this.saveFirstMainHotelData();
          }
        }
      } else {
        // this.toaster.error(resp.message);
      }
    }, error => {
      // this.toaster.error('Something went wrong, Please try again later!');
    });
  }

  saveFirstMainHotelData() {
    this.coreService.mainSelectedHotel = this.headerHotelList[0];
    this.hotelSelectionControl.setValue(this.headerHotelList[0].name);
    localStorage.setItem('mainHotel', JSON.stringify(this.headerHotelList[0]));
  }

  filterHotel(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredHotelOptions = this.headerHotelList.filter(o => o.name.toLowerCase().includes(filterValue));
  }

  getAllHotel(): void {
    this.filteredHotelOptions = this.headerHotelList.filter(a => a);
  }

  onHotelSelectionChange(event: any) {
    console.log(event.source.id);
    this.onHotelChange(Number(event.source.id));
  }

  onHotelChange(hotelId: number) {
    const selectedObj = this.headerHotelList.find(p => p.id === hotelId);
    this.coreService.mainSelectedHotel = selectedObj;
    this.hotelSelectionControl.setValue(selectedObj.name);
    localStorage.setItem('mainHotel', JSON.stringify(selectedObj));

    setTimeout(() => {
      this.coreService.mainHotelChanged.emit(true);
    }, 10);
  }

  protected readonly Number = Number;
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [
    RouterModule,
    MaterialModule,
    TablerIconsModule,
    FormsModule,
    NgScrollbarModule,
  ],
  templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

}
