import {HttpEvent, HttpInterceptorFn, HttpResponse} from '@angular/common/http';
import {map} from "rxjs/operators";

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(map((event: HttpEvent<any>) => {
    if (event instanceof HttpResponse) {
      const modifiedBody = event.body.response;
      return event.clone({ body: modifiedBody });
    }
    return event;
  }));
};
