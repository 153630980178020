import {EventEmitter, Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, defaults } from '../config';
import {Hotel} from "../pages/masters/classes/hotel";
import {ConfirmDialogComponent} from "../common-components/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class CoreService {

  mainSelectedHotel: Hotel = null;
  mainHotelChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  hotelMasterDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  paginationOptionList: number[] = [10, 25, 50, 100];

  constructor(public dialog: MatDialog,
              private router: Router) {
  }

  get notify(): Observable<Record<string, any>> {
    return this.notify$.asObservable();
  }

  private notify$ = new BehaviorSubject<Record<string, any>>({});

  getOptions() {
    return this.options;
  }

  setOptions(options: AppSettings) {
    this.options = Object.assign(defaults, options);
    this.notify$.next(this.options);
  }

  private options = defaults;

  getLanguage() {
    return this.options.language;
  }

  setLanguage(lang: string) {
    this.options.language = lang;
    this.notify$.next({ lang });
  }

  logoutClick() {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '290px',
      data: {
        title: 'Confirm',
        message: 'Are you sure you want to logout?'
      }
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result?.event) {
        this.router.navigate(['/authentication/login']);
      }
    });
  }
}
