export class ApiHeaders {

  static getOptions() {
    return {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  }
}
