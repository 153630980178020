import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {RestResponse} from "../classes/rest-response";
import {ApiHeaders} from "../classes/api-headers";

@Injectable()
export class MasterProvider {
  baseUrl: string = environment.BASE_URL;

  constructor(private http: HttpClient) {
  }

  getHotelGroupList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/HotelInfo/GetHotelGroups`, ApiHeaders.getOptions());
  }

  addHotelGroup(name: string, isActive: boolean, formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/HotelInfo/AddHotelGroup?Name=${name}&IsActive=${isActive}`, formData);
  }

  updateHotelGroup(formData: FormData): Observable<RestResponse> {
    return this.http.put<RestResponse>(this.baseUrl + `/api/HotelInfo/EditHotelGroup`, formData);
  }

  deleteHotelGroup(id: number): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.baseUrl + `/api/HotelInfo/DeleteHotelGroup?Id=${id}`, ApiHeaders.getOptions());
  }

  getHotelList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/HotelInfo/GetHotelList`, ApiHeaders.getOptions());
  }

  getGetHotelBindByUserId(userId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/HotelInfo/GetHotelBindByUserId?UserId=${userId}`, ApiHeaders.getOptions());
  }

  addHotel(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/HotelInfo/AddHotel`, formData);
  }

  updateHotel(formData: FormData): Observable<RestResponse> {
    return this.http.put<RestResponse>(this.baseUrl + `/api/HotelInfo/EditHotel`, formData);
  }

  deleteHotel(id: number): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.baseUrl + `/api/HotelInfo/DeleteHotel?Id=${id}`, ApiHeaders.getOptions());
  }

  getHotelFranchiseList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/HotelInfo/GetFranchisehotelList`, ApiHeaders.getOptions());
  }

  getCountryList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MasterSetting/GetAllCountry`, ApiHeaders.getOptions());
  }

  addState(saveObj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/MasterSetting/AddState`, saveObj, ApiHeaders.getOptions());
  }

  updateState(saveObj: any): Observable<RestResponse> {
    return this.http.put<RestResponse>(this.baseUrl + `/api/MasterSetting/UpdateState`, saveObj, ApiHeaders.getOptions());
  }

  getStateList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MasterSetting/GetAllStates`, ApiHeaders.getOptions());
  }

  deleteState(id: number): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.baseUrl + `/api/MasterSetting/DeleteState/${id}`, ApiHeaders.getOptions());
  }

  addCity(saveObj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/MasterSetting/AddCity`, saveObj, ApiHeaders.getOptions());
  }

  updateCity(saveObj: any): Observable<RestResponse> {
    return this.http.put<RestResponse>(this.baseUrl + `/api/MasterSetting/UpdateCity`, saveObj, ApiHeaders.getOptions());
  }

  getCityList(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MasterSetting/GetAllCities`, ApiHeaders.getOptions());
  }

  deleteCity(id: number): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.baseUrl + `/api/MasterSetting/DeleteCity/${id}`, ApiHeaders.getOptions());
  }

  getSelectAccessHotelByUser(id: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/HotelInfo/GetSelectAccessHotelByUser?UserId=${id}`, ApiHeaders.getOptions());
  }

  removeAccessHotelByUser(id: number): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.baseUrl + `/api/HotelInfo/RemoveAccessHotelByUserId?UserId=${id}`, ApiHeaders.getOptions());
  }

  addUpdateAccessUserHotel(userId: number, hotelId: number, createdBy: number): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/HotelInfo/PostAccessUserHotel?UserId=${userId}&HotelId=${hotelId}&CreatedBy=${createdBy}`, ApiHeaders.getOptions());
  }

  getAllCountry(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MasterSetting/GetAllCountry`);
  }

  getAllStatesByCountryId(countryId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MasterSetting/GetAllStatesByCountryId?CountryId=${countryId}`);
  }

  getAllCitiesByStateId(stateId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MasterSetting/GetAllCitiesByStateId?StateId=${stateId}`);
  }

  getAllMenus(): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MenuAccess/GetMenuList`, ApiHeaders.getOptions());
  }

  addMenu(formData: FormData): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/MenuAccess/AddMenu`, formData);
  }

  updateMenu(formData: FormData): Observable<RestResponse> {
    return this.http.put<RestResponse>(this.baseUrl + `/api/MenuAccess/EditMenu`, formData);
  }

  deleteMenu(id: number): Observable<RestResponse> {
    return this.http.delete<RestResponse>(this.baseUrl + `/api/MenuAccess/DeleteMenu?id=${id}`, ApiHeaders.getOptions());
  }

  getMenuRightsAdminListByUserID(userId: number): Observable<RestResponse> {
    return this.http.get<RestResponse>(this.baseUrl + `/api/MenuAccess/MenuRightsAdminListByUserID?Userid=${userId}`, ApiHeaders.getOptions());
  }

  saveMenuRights(obj: any): Observable<RestResponse> {
    return this.http.post<RestResponse>(this.baseUrl + `/api/MenuAccess/AddMenuRights`, obj, ApiHeaders.getOptions());
  }
}
