import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const token: any = localStorage.getItem('WD-TOKEN');
  if (token) {
    return true;
  }
  router.navigate(['/authentication/login']);
  return false;
};
