export class User {
  id: number;
  email: string;
  password: string;
  name: string;
  phone: string;
  createdOn: Date;
  roleType: string;
  gender: string;
  firstName: string;
  lastName: string;
  countryId:number;
  cityId: number;
  stateId: number;

  constructor(value: any = {}) {
    Object.assign(this, value);
  }

  static parseArray(json: any[]): User[] {
    const arr = [];

    if (json) {
      for (const j of json) {
        if (j) {
          arr.push(new User(j));
        }
      }
    }

    return arr;
  }
}
